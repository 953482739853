import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DEFAULT_PERSONAS } from "./Persona";
import { getTokenOrRefresh, postURL, realtimeFeedback } from "../utils";
import ChatStatus from "./ChatStatus";
import ChatInput from "./ChatInput";
// import '../assets/styles/ChatDetails.css';
import ChatOutput from "./ChatOutput";
import ChatHistory from "./ChatHistory";
import { MessageResponse } from "../models/Simulation";
import { Dialog } from "@mui/material";
import { SummaryBox } from "./SummaryBox";
import { getMessageLimit } from "../utils";
import { endChat, getDataFromLocalStorage, setDataInLocalStorage,fetchAuthenticatedUrl,checkIfRandomNumberIsOne } from "../utils";
const CHAT_OUTPUT = "o";
const CHAT_INPUT = "i";

function ChatDetails({ simulation, chat, personaId, refreshCheckList, showFeedback, setFeedbackActive,summary }) {
  const location = useLocation();
  const [persona, setPersona] = useState();
  const [error, setError] = useState(false);
  const [azureToken, setAzureToken] = useState(false);
  const [messages, setMessages] = useState(chat.messages);
  const [nextStage, setNextStage] = useState(CHAT_INPUT);
  const [userMessage, setUserMessage] = useState("");
  const [botMessage, setBotMessage] = useState("");
  const historyContainerRef = useRef(null); //used for auto-scroll
  const simId = localStorage.getItem("sim_id");
  const chatId = localStorage.getItem("chat_id");
  const [personaImage,setPersonaImage] = useState()
  const [audioBlob, setAudioBlob] = useState(null);
  const [feedbackMessages, setFeedbackMessages] = useState({});
  const [feedbackConfig, setFeedbackConfig] = useState(simulation.config.realtimeFeedbackConfig);
  const [showSummaryBox, setShowSummaryBox] = useState(false);
 const [messageLimitReached,setMessageLimitReached] = useState(false);
  const navigate = useNavigate();

  const chatInputURL = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${
    simulation.simulationId || simId
  }/${chat.chatId || chatId}/msg`;

  const appendMessage = (sender, content) => {
    const newMessage = new MessageResponse(sender, content, new Date());
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };
  useEffect(() => {
    const feedbackMesages = getDataFromLocalStorage(`feedbackMessages-${simulation.simulationId}-${chat.chatId}`);
    if(feedbackMesages){
      setFeedbackMessages(JSON.parse(feedbackMesages));
    }
  }, []);

  useEffect(() => {
   if(messages.length>=getMessageLimit()){
    setMessageLimitReached(true);
    handleEndChat(false);
   }
  }, [messages]);

  useEffect(() => {
    const fetchRealTimeFeedback = async () => {
      console.log("fetching real time feedback",messages,audioBlob,simulation);
      if(feedbackConfig.length===0){
        return;
      }
      const resp = await realtimeFeedback(`${process.env.REACT_APP_REALTIME_FEEDBACK_BACKEND_HOST}/realtime-feedback`, messages, audioBlob, simulation.simType,feedbackConfig);
      setFeedbackMessages({...feedbackMessages, [messages.length-1]: {feedback: resp.response}});
      if(Object.keys(resp.response).length>0){
        console.log("feedback config",feedbackConfig);
        feedbackConfig.forEach(item=>{
          console.log("feedback config",item,resp.response[item]?.score);
         if(resp.response[item]?.score>=1){
          console.log("feedback active",item);
          setFeedbackActive(true);
         }
        })
      }
      setDataInLocalStorage(`feedbackMessages-${simulation.simulationId}-${chat.chatId}`,JSON.stringify({...feedbackMessages, [messages.length-1]: {feedback: resp.response}}));
      console.log("real time feedback response",resp);
    }
    if(audioBlob&&messages.length>4&&checkIfRandomNumberIsOne()){
      fetchRealTimeFeedback();
    }
  }, [simulation,audioBlob]);
  useEffect(() => {
    const fetchImgUrl = `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/${personaId}/image`;
    postURL(fetchImgUrl).then((resp) => {
      console.log(resp);
      setPersonaImage(resp.data);
    });
    console.log("persona image is ", personaImage);
  }, [personaId]);
  useEffect(() => {
    const setup = async () => {
      setPersona({
        ...DEFAULT_PERSONAS.find((p) => p.id === personaId),
        id: personaId,
      });
      // console.log("persona and chat session initiated successfully!");

      if (!azureToken) {
        const tokenObj = await azureRecorderSetup();
        // console.log("azure token is set!", tokenObj);
        setAzureToken(tokenObj);
      }
    };

    setup();
  }, [azureToken]);

  useEffect(() => {
    console.log("Setting up Azure token refresh interval...");
    const interval = setInterval(async () => {
      console.log("Refreshing Azure token...");
      try {
        const tokenObj = await azureRecorderSetup();
        setAzureToken(tokenObj);
      } catch (error) {
        console.error("Failed to refresh Azure token:", error);
        setError(true);
      }
    }, 60000); // Refresh every 1 minute

    return () => {
      console.log("Clearing Azure token refresh interval...");
      clearInterval(interval);
    };
  }, []);

  const azureRecorderSetup = async () => {
    let tokenObj = await getTokenOrRefresh();
    console.log("Token object:", tokenObj);
    if (tokenObj.error) {
      setError(true);
      throw new Error("Failed in setting up this chat!");
    }
    return tokenObj;
  };

  function chatInputComplete(user_message, bot_message) {
    appendMessage("U", user_message);
    // console.log(
    //   "chat input is complete...now show the output with message..",
    //   bot_message
    // );
    setNextStage(CHAT_OUTPUT);
    setBotMessage(bot_message);
  }

  function chatOutputComplete(bot_message) {
    appendMessage("B", bot_message);
    refreshCheckList();
    // console.log("chat output is now complete..", bot_message);
    setNextStage(CHAT_INPUT);
    setBotMessage("");
  }


  //This method helps in scrolling to the bottom of the div
  const setHistoryContainerRef = (element) => {
    if (element) {
      historyContainerRef.current = element;
      historyContainerRef.current.scrollTop =
        historyContainerRef.current.scrollHeight;
    }
  };
  const handleEndChat = async (shouldRedirect = true) => {
    console.log("ending chat", simulation.simulationId, chat.chatId);
    let response;
    if(simulation.summaryRequired){
      response = await endChat(simulation.simulationId, chat.chatId, summary);
  
      
    }else{
      
      response = await endChat(simulation.simulationId, chat.chatId, summary);
     
    }
    if(response.data && shouldRedirect){
      navigate(`/sim/${simulation.simulationId}/${chat.chatId}/transcript`);
    }
    return;
  }

  





  return (
    <div className="container min-h-[75vh] max-h-[80vh] overflow-hidden flex flex-col">
      {simulation && azureToken.authToken && (
        <>
          <div className="grid grid-cols-1 gap-4">
            {/* <div>
                <Persona person={persona} />
            </div> */}
            <div>
              <ChatStatus simulation={simulation} chat={chat} personaImage={personaImage} onClose={handleEndChat} />
            </div>
          </div>
          <div
            ref={setHistoryContainerRef}
            className="flex-1 overflow-y-auto flex flex-col h-screen justify-between items-center"
          >
            <div id="messages" className="w-full h-fit overflow-y-auto mb-10">
              <ChatHistory messages={messages} personaImage={personaImage} feedbackMessages={feedbackMessages} showFeedback={showFeedback} />
              <div></div>
              <div>
                {nextStage === CHAT_OUTPUT && (
                  <ChatOutput
                    simulation={simulation}
                    azureToken={azureToken}
                    message={botMessage}
                    onComplete={chatOutputComplete}
                    setAzureToken={setAzureToken}
                    setError={setError}
                  />
                )}
              </div>
            </div>
            <div className="sticky bottom-0 rounded-lg w-fit">
              <div className="max-w-md mx-auto">
                
                  <ChatInput
                    url={chatInputURL}
                    azureToken={azureToken}
                    onComplete={chatInputComplete}
                    setAzureToken={setAzureToken}
                    setError={setError}
                    refreshCheckList={refreshCheckList}
                    setAudioBlob={setAudioBlob}
                    showInputElement={nextStage === CHAT_INPUT }
                  />
                
              </div>
            </div>
          </div>
        </>
      )}

      {!location.state && !azureToken.authToken && (
        <div>Unable to start this chat! Redirecting in 5 seconds...</div>
      )}
      
      { 
        error && (
          <Dialog open={true} onClose={() => window.location.reload()}>
            <div className="p-4">
              <p>Refresh this page to continue from where you left off.</p>
              <button
                className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => window.location.reload()}
              >
                Refresh
              </button>
            </div>
          </Dialog>
        )

      }
      {messageLimitReached && (
        <Dialog open={true} onClose={() => navigate(`/sim/${simulation.simulationId}/${chat.chatId}/transcript`)}>
          <div className="p-4">
            <p>This role play has now ended. Thank you for engaging with this simulation. To ensure an effective and focused practice experience, each session is designed to fit within a set timeframe.</p>
            <button
                className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => navigate(`/sim/${simulation.simulationId}/${chat.chatId}/transcript`)}
              >
                Okay
              </button>
          </div>
          
        </Dialog>
      )}
    </div>
  );
}

export default ChatDetails;
