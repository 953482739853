import axios from "axios";
import Cookie from "universal-cookie";

const HOST = process.env.REACT_APP_SIM_BACKEND_HOST;

export const getCookie = (name: string ) => {
  const value = `; ${document.cookie}`;
  console.log("value", value);
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
};

export async function getTokenOrRefresh() {
  const cookie = new Cookie();
  const speechToken = cookie.get("speech-token");
  const accessToken = localStorage.getItem("accessToken");

  // Check if the token is expired or about to expire (within 30 seconds)
  const isTokenExpired = () => {
    if (!speechToken) return true;
    const [, token] = speechToken.split(':');
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 < Date.now() + 30000; // 30 seconds buffer
  };

  if (speechToken === undefined || isTokenExpired()) {
    try {
      const res = await axios.post(`${HOST}/sim/refresh`, null, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        timeout: 5000, // Set a timeout of 5 seconds
      });
      const token = res.data.az_token;
      const region = res.data.region;
      cookie.set("speech-token", region + ":" + token, {
        maxAge: 100,
        path: "/",
      });

      return { authToken: token, region: region };
    } catch (err: any) {
      console.error("Error refreshing token:", err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response data:", err.response.data);
        console.error("Response status:", err.response.status);
        console.error("Response headers:", err.response.headers);
      } else if (err.request) {
        // The request was made but no response was received
        console.error("No response received:", err.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", err.message);
      }
      return { authToken: null, error: err };
    }
  } else {
    const idx = speechToken.indexOf(":");
    return {
      authToken: speechToken.slice(idx + 1),
      region: speechToken.slice(0, idx),
    };
  }
}

export async function sendBlobToBackend(url: string, blob: any, text: string) {
  const accessToken = localStorage.getItem("access_token");
  const formData = new FormData();
  formData.append("audio", blob);
  formData.append("text", text);
  try {
    const res = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + accessToken,
      },
    });
    // console.log("Response:", res.bot_response);
    if (res.status === 401) {
      return res;
    }
    return res.data.bot_response;
    
  } catch (error) {
    console.error("Error:", error);
    // Handle the error as needed
  }
}

export async function fetchAuthenticatedUrl(url: string) {
  try {
    let accessToken = localStorage.getItem("access_token");
    if (accessToken === "undefined"||accessToken === null) {
      const token = getCookie("access_token");
      console.log("token", token);
      if (!token) {
        throw new Error("No access token found");
      }
      localStorage.setItem("access_token", token);
      accessToken = token;
    }
    console.log("fetching from...", url);
    console.log("access token", accessToken);
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: "Bearer " + accessToken,
      }),
    });
    if (!response.ok) {
      if (response.status === 401) {
        return response;
      }else{
        throw new Error("Failed to fetch simulation data");
      }
    }
    const resp = await response.json();
   
    return {
      data: resp.data,
      error: undefined,
    };
  } catch (error) {
    console.log("error", error);
    return {
      data: undefined,
      error: error,
    };
  }
}

export async function postURL(url: string, data: FormData = new FormData()) {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + accessToken,
      },
    });
    return {
      data: response.data,
      error: undefined,
    };
  } catch (error) {
    return {
      data: undefined,
      error: error,
    };
  }
}

export async function endChat(public_sim_id: string, public_chat_id: string, summary?: string) {
  const accessToken = localStorage.getItem("accessToken");  
  const response = await axios.post(`${HOST}/sim/${public_sim_id}/${public_chat_id}/end`, {summary}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: "Bearer " + accessToken,
    },
  });
  return response;
}

export const setDataInLocalStorage = (key: string, value: string, expiry: number = 48) => {
  const expiryDate = new Date(Date.now() + expiry * 60 * 60 * 1000).toISOString();
  const valueWithExpiry = {value, expiry: expiryDate};
  localStorage.setItem(key, JSON.stringify(valueWithExpiry));
}

export const getDataFromLocalStorage = (key: string) => {
  const valueWithExpiry = JSON.parse(localStorage.getItem(key) || '{}');
  if (valueWithExpiry.expiry && new Date(valueWithExpiry.expiry) < new Date()) {
    localStorage.removeItem(key);
    return null;
  }
  return valueWithExpiry.value;
}
interface RootObject {
  status: string;
  message_count: number;
  response: Response;
}
interface Response {
  empathy: Empathy;
  partnership: Partnership;
  softening_sustain_talk: Softeningsustaintalk;
  cultivating_change_talk: Cultivatingchangetalk;
  voice_qa?: any;
}
interface Cultivatingchangetalk {
  clinician_cct_score: number;
  clinician_cct_better1: string;
  clinician_cct_better2: string;
}
interface Softeningsustaintalk {
  clinician_scct_score: number;
  clinician_scct_better1: string;
  clinician_scct_better2: string;
}
interface Partnership {
  clinician_p_score: number;
  clinician_p_better1: string;
  clinician_p_better2: string;
}
interface Empathy {
  clinician_e_score: number;
  clinician_e_better1: string;
  clinician_e_better2: string;
}

export async function realtimeFeedback(url: string, messages: any, audio: any, sim_type: string,feedbackConfig: string[]) : Promise<RootObject> {
  const formData = new FormData();
  formData.append('messages', JSON.stringify(messages));
  formData.append('audio', audio);
  formData.append('sim_type', sim_type);
  formData.append('feedbackConfig', JSON.stringify(feedbackConfig));
  const accessToken = localStorage.getItem("accessToken");
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: "Bearer " + accessToken,
    },
  });
  return response.data;
} 



// const keyValuePairs = {
//     'uncover_patients_own_understanding_of_health': "Uncover patient's own understanding of their health",
//     'twelve_conversational_exchanges_complete': "Explore the client's challenges in sufficient detail",
//     'clinician_has_clarified_end_of_life_prognosis': "Clarify end of life prognosis to the patient",
//     'patient_agreed_to_create_plan': "Get the patient to agree to creating a future health plan",
//     'importance_of_end_of_life_planning_discussed': "Emphasize the important of end of life planning",
//     'prognosis_progression_scenarios': "Describe the prognosis, progression, and health scenarios at end of life",
//     'incapacitaion_decision_making': "Describe how patient's may become incapacitated at end of life",
//     'options_care_pain_management': "Describe options for comfort care and pain management",
//     'decisions_healthcare_agent': "Describe decisions that a healthcare agent would make",
//     'discovery_of_whats_important_for_end_of_life_care': "Discover what is important for the patient in end of life care",
//     'discovery_of_past_experience_with_others_end_of_life': "Explore any past experience with others' end of life",
//     'counseled_on_where_to_gather_information': "Counsel patient on where to gather information",
//     'summarize_patients_concerns_and_perspective': "Summarize the conversation thus far",
//     'identify_healthcare_agent': "Identify patient's healthcare agent",
//     'identify_preference_for_life_sustaining_treatment': "Document's patient preferences for what life sustaining treatment they'd want at end of life",
//     'identify_preference_of_comfort_care_and_pain_management_options': "Document's patient preferences for pain management and comfort care",
//     'gather_preference_for_others_behavior': "Document's patient preferences for how they want loved ones to behave around them",
//     'identify_wishes_for_loved_ones': "Document's patient's wishes for their loved ones",
//     'two_reflections_used_in_response': "Use reflections to build rapport",
//     'two_open_questions_used_in_response': "Use open questions to learn more about the client",
//     'two_affirmations_used_in_response': "Use affirmations to encourage the client",
//     'client_shared_health_challenge': "Get the client to share their health challenge in detail",
//     'twelve_exchanges_complete': "Explore the client's challenges in sufficient detail",
//     'try_to_get_client_to_define_ideal_health': "Ask the client to visualize what their ideal health outcome would look like",
//     'client_shared_view_of_ideal_health': "Get the client to articulate what their ideal health would be",
//     'describe_how_cindy_could_change_behavior': "Describe your philosophy for how Cindy could change behaviors",
//     'emphasize_belief_in_clients_self_efficacy': "Emphasize your belief in the client's self efficacy",
//     'outline_how_when_client_coach_engagement': "Outline how and when you will engage with the client",
//     'describe_smart_goal_setting': "Describe SMART goal setting",
//     'redirect_to_goal_setting': "Redirect the patient towards setting goals",
//     'suggest_multiple_goals': "Suggest multiple goals to the patient",
//     'articulate_goal_in_smart_format': "Articulate the client's preferred goal in a SMART format",
//     'obtain_commitment_from_client': "Obtain commitment from the client on taking action on the goal"
//   };

// export function prettyPrintChecklist(input) {
//   const trimmedInput = input.trim().toLowerCase();
//   for (const [key, value] of Object.entries(keyValuePairs)) {
//     if (trimmedInput === key.toLowerCase()) {
//       return value;
//     }
//   }
//   return input;
// }
export interface Stage {
  title: string;
  subgoals: String[];
}

export interface Instructions {
  intro: string;
  stages: Stage[];
}
const healthCoachInstructions = {
intro:
"In this role play, you'll practice as a health coach having their first session with a client aiming to get healthier and lose weight. The client may stray off topic, so you'll need to use appropriate coaching techniques while empathetically guiding them through 4 stages and achieving the sub-goals within each.",
stages: [
{
  title: "Establish rapport",
  subgoals: [
    "Demonstrate presence through reflections",
    "Show interest with open questions",
    "Encourage with affirmations",
    "Get patient to share health challenges",
  ],
} as Stage,
{
  title: " Visualize better future",
  subgoals: [
    "Discuss ideal future health state",
    "Help overcome visualization impediments",
  ],
} as Stage,
{
  title: "Explain coaching process",
  subgoals: [
    "Describe behavior change philosophy",
    "Emphasize client's self-efficacy",
    "Outline interaction frequency",
    "Explain SMART goal setting",
  ],
} as Stage,
{
  title: "Set goals",
  subgoals: [
    "Redirect towards goal setting",
    "Propose three distinct goals",
    "Get commitment to one goal",
    "Frame goal in SMART format",
  ],
} as Stage,
],
} as Instructions;
const ccmMaggieDay2 = {
  intro: "In this simulated exercise, you will act as a transition of care nurse following up with a recently discharged patient. Your primary goal is to assess the patient’s current condition, and address barriers to care. Begin by introducing yourself and establishing rapport with the patient, ensuring they feel supported in their transition home. Assess the patient’s current symptoms, medication adherence, and any immediate concerns. Identify gaps in care. Explore available resources to support medication adherence. Educate the patient on the importance of managing her chronic conditions and encourage follow-up appointments. Additionally, discuss lifestyle modifications, and assist in scheduling an appointment with a primary care provider.",
  stages: [
    {
      title: "Building Rapport, Current Symptoms, & Initial Medication Reconciliation",
      subgoals: [
        "Ensure proper patient identification and introduction",
        "Assess patient’s current health status and concerns",
        "Discuss care coordination and access to services",
        "Review and reconcile all medications",
      ],
    } as Stage,
    {
      title: "Uncovering Medical and Social Barriers",
      subgoals: [
        "Assess the patient’s financial concerns, including medication affordability and work-related challenges.",
        "Clarify the patient’s living situation, caregiving duties, and related stressors.",
        "Identify any missed or inconsistently taken prescriptions due to cost or side effects.",
        "Uncover social barriers affecting adherence to the care plan.",
      ],
    } as Stage,
    {
      title: "Providing Solutions and Exploring Patient Readiness",
      subgoals: [
        "Identify and suggest cost-saving options for prescriptions.",
        "Provide scheduling solutions to accommodate the patient’s needs.",
        "Address medication concerns and offer strategies to minimize disruptions.",
        "Discuss lifestyle modifications and assess the patient’s support needs.",
      ],
    } as Stage,
    {
      title: "Establishing a Care Plan and Securing Follow-Up",
      subgoals: [
        "Summarize the care plan, including next steps and follow-up actions.",
        "Clarify important symptoms to monitor and when to seek urgent care.",
        "Confirm the patient’s understanding, agreement, and access to support.",
        "Arrange follow-up to reassess progress and adjust the plan.",
      ],
    } as Stage,
  ],
};


const endOfLifeSimulation = {
intro: "Your goal is to speak with a patient, John, about end of life planning. Show empathy as you educate and inform them, and ultimately get them to make certain decisions. Explore their challenges and guide them through four stages of counseling. To move forward, you'll need to complete the tasks in each stage's sub-goals. However, don't limit yourself to just these tasks, as you'll be evaluated on how well you follow best practices for end of life planning conversations. Here is the patient's medical history.",
stages: [
{
  title:
    "Initiating and framing the end-of-life planning conversation",
  subgoals: [
    "Introduce yourself and verify patient's identity",
    "Uncover patient's own understanding of their health related to all their conditions",
    "Clarify end of life prognosis to the patient",
    "Get the patient to agree to learning about end of life planning",
    "Emphasize the important of end of life planning",
    "Explore the client's challenges in sufficient detail",
  ],
} as Stage,
{
  title:
    "Providing information on end-of-life medical scenarios and care options",
  subgoals: [
    "Describe the prognosis, progression, and health scenarios at end of life",
    "Describe how a patient may become incapacitated at end of life",
    "Describe options for comfort care and pain management",
    "Describe decisions that a healthcare agent would make",
  ],
} as Stage,
{
  title: "Exploring patient values and expectations for end-of-life care",
  subgoals: [
    "Ask the patient about what they expect from their treatment and medical team",
    "Explore any past experience with others' end of life",
    "Counsel patient on where to gather information",
    "Discover what is important for the patient in end of life care",
  ],
} as Stage,
{
  title: "Documenting and finalizing patient preferences for end-of-life care",
  subgoals: [
    "Summarize the conversation thus far",
    "Identify patient's healthcare agent",
    "Document patient’s preferences for what life sustaining treatment they'd want at end of life",
    "Document patient’s preferences for pain management and comfort care",
    "Document patient’s preferences for how they want loved ones to behave around them",
    "Document patient's wishes for their loved ones",
  ],
} as Stage,
],
};

const anesthesiaInformedConsentSimulation = {
intro: "In this role play your goal is to discuss anesthesia options with a patient and counsel them with the ultimate aim of getting verbal consent. Show empathy as you explore their challenges and guide them through four stages. To move forward, you'll need to complete the tasks in each stage's sub-goals. However, don't limit yourself to just these tasks, as you'll be evaluated on how well you follow overall best practices for such a conversation. Here's the patient's medical history, and current setting. ",
stages: [
{
  title:
    "Introduction and gathering patient history",
  subgoals: [
    "Identify patient's primary fears",
    "Verify patient identity",
    "Encourage patient to share fears",
    "Introduce yourself",
    "Explain the goal of the conversation",
  ],
} as Stage,
{
  title:
    "Educate the patient on anesthesia options",
  subgoals: [
    "Ask about anesthesia fears",
    "Discuss benefits and risks of general anesthesia",
    "Discuss benefits and risks of regional anesthesia",
    "Relate general anesthesia benefits to patient concerns",
    "Relate regional anesthesia benefits to patient concerns",
    "Counter misconceptions with evidence-based explanations",
    
  ],
} as Stage,
{
  title: "Counsel patient on post-operative pain management techniques",
  subgoals: [
    "Ask about patient's concerns on postoperative pain",
    "Detail pain management options with regional anesthesia",
    "Detail pain management options with general anesthesia", 
    "Describe medication-based pain management including opioids and non-opioids",
  ],
} as Stage,
{
  title: "Finalize the plan and get consent",
  subgoals: [
    "Present alternative strategies for anesthesia",
    "Outline contingency plan for anesthesia",
    "Summarize the final anesthesia plan",  
    "Confirm patient understanding and obtain verbal consent",
  ],
} as Stage,
],
};


const transitionToCareSimulation = {
intro:
"Your goal is to conduct a transitional care call with a patient who was recently discharged from the hospital. Show empathy as you explore their challenges and guide them through four stages of care. To move forward, you'll need to complete the tasks in each stage's sub-goals. However, don't limit yourself to just these tasks, as you'll be evaluated on how well you follow overall best practices for transition of care conversations. Here's the patient's medical history.",
stages: [
{
  title:
    "Introduction and Medical Review",
  subgoals: [
    "Greet the patient and verify identity.",
    "Explain the purpose of the call.",
    "Ask about the recent hospitalization and any complications.",
    "Inquire about current symptoms.",
  ],
} as Stage,
{
  title:
    "Health Status and Medication Review",
  subgoals: [
    "Ask about symptoms related to comorbidities.",
    "Review medications.",
    "Identify and address misconceptions about medication and diet.",
  ],
} as Stage,
{
  title: "Clarifications and Support Resources",
  subgoals: [
    "Clarify confusion about doctor’s guidance.",
    "Explain fluid intake recommendations.",
    "Stress the importance of not skipping medications or changing diet without consultation.",
    "Inquire about the need for community resources.",
    "Discuss DMEs.",
    "Discuss end-of-life planning.",
  ],
} as Stage,
{
  title: "Risk Management and Follow-up",
  subgoals: [
    "Identify risks for rehospitalization and strategies to prevent them.",
    "Set clear expectations for physician appointments.",
    "Summarize actions for medication management, follow-ups, and resources.",
    "Schedule follow-up appointments.",
  ],
} as Stage,
],
};

const tocDay7SallyUpperGI = {
  intro:
  "Your task is to conduct a 7-day TOC call with the patient. Uncover all immediate medical and psychosocial concerns. Conduct a thorough medication review and take action to educate or escalate as appropriate. Create patient centered activities for the patient’s plan of care that target preventing a future admission. End the call with a summary of all your assessments, advice, and planning, i.e., anything that you would include in a SOAP note. \n You had a 2 day TOC call with them where you learnt that they had a PPI (proton pump inhibitor) added to their medication list and that they were feeling more fatigued than usual. They self-reported a post-discharge hemoglobin of 7. You educated them on dietary restrictions considering their GI bleed.\nPayer is IKC.",
  stages: [
  {
    title:
      "Introduction and Medical Review",
    subgoals: [
      "Introduce yourself and verify the patient’s identity.",
      "Assess symptoms and review medications for potential issues.",
      "Evaluate key lab data",
      "Conduct a comprehensive health assessment",
    ],
  } as Stage,
  {
    title: "Education",
    subgoals: [
      "Educate the patient on medication use and potential risks.",
      "Coordinate care regarding high-risk medication combinations.",
      "Review and update diet, medication, and care plans.",
      "Reinforce adherence to monitoring and lifestyle recommendations.",
    ],
  } as Stage,
  {
    title:
      "Provide support",
    subgoals: [
      "Confirm and facilitate scheduling of necessary follow-up appointments.",
      "Recommend alternatives for glucose monitoring and address patient concerns.",
      "Provide explanations for symptoms, suggest solutions, and propose topics for specialist consultation.",
      "Highlight critical health indicators and recommend ongoing monitoring.",
    ],
  } as Stage,
  {
    title: "Risk Management and Follow-up",
    subgoals: [
      "Communicate critical health priorities to the patient.",
      "Educate on GI bleeding signs and when to seek help.",
      "Confirm care plan agreement and arrange follow-up.",
      "Address fall prevention, low albumin, and high potassium management.",
      
    ],
  } as Stage,
  ],
  };
export const SIM_CONFIGS = {
      EOL: {
        voice: "en-US-DavisNeural",
        style: "sad",
        styleDegree: "1.2",
        role: "OlderAdultMale",
        instructions: endOfLifeSimulation
      },
      TRANSITION_OF_CARE_FINAL: {
        voice: "en-US-DavisNeural", 
        style: "sad",
        styleDegree: "1.2",
        role: "OlderAdultMale",
        instructions: transitionToCareSimulation
      },
      NEW_EOL: {
        voice: "en-US-DavisNeural",
        style: "sad", 
        styleDegree: "1.2",
        role: "OlderAdultMale",
        instructions: endOfLifeSimulation
      },
      HEALTH_COACHING: {
        voice: "en-US-MonicaNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: healthCoachInstructions
      },
      ANESTHESIA_INFORMED_CONSENT: {
        voice: "en-US-LolaMultilingualNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: anesthesiaInformedConsentSimulation
      },
      DEFAULT: {
        voice: "en-US-LolaMultilingualNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: anesthesiaInformedConsentSimulation
      },
      TOC_DAY7_SALLY_UPPER_GI: {
        voice: "en-US-LolaMultilingualNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: tocDay7SallyUpperGI
      },
      TOC_DAY7_SALLY_UPPER_GI_ASSESSMENT: {
        voice: "en-US-LolaMultilingualNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: tocDay7SallyUpperGI
      },
      HEALTH_COACHING_ASSESSMENT: {
        voice: "en-US-MonicaNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: healthCoachInstructions
      },
      CCM_MAGGIE_DAY2: {
        voice: "en-PH-RosaNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: ccmMaggieDay2
      },
      CCM_MAGGIE_DAY2_ASSESSMENT: {
        voice: "en-PH-RosaNeural",
        style: "hopeful",
        styleDegree: "1.2",
        instructions: ccmMaggieDay2
      },
      SALES_HEALTHTECH_MSK_1: {
        voice: "en-US-AndrewMultilingualNeural",
        style: "sad",
        styleDegree: "1.2",
        instructions: ccmMaggieDay2
      }
    };


export const FEEDBACK_KEYS = {
  cultivating_change_talk: "Demonstrating cultivating change talk",
  empathy: "Demonstrating empathy", 
  voice_qa: "Style",
  partnership: "Demonstrating partnership",
  softening_sustain_talk: "Demonstrating softening sustain talk"
}

export const generateRandomNumber = (min: number, max: number) => { 
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  console.log("random number",randomNumber);
  return randomNumber;
}

export const checkIfRandomNumberIsOne = () => {
  return generateRandomNumber(1, 3) === 1;
}

export const getMessageLimit = () => {
  return 200;
}