import React, { useState, useEffect, useRef } from "react";
import { sendBlobToBackend, getTokenOrRefresh } from "../utils";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { useAudioRecorder } from "react-audio-voice-recorder";
import TypingBubble from "./TypingBubble";
import { useNavigate } from "react-router-dom";

// Types
interface AzureToken {
  authToken: string | null;
  region?: string;
  error?: string;
}

interface ChatInputProps {
  url: string;
  azureToken: AzureToken | null;
  onComplete: (transcript: string[], botResponse: any) => void;
  setAzureToken: (token: AzureToken) => void;
  setError: (error: boolean) => void;
  refreshCheckList?: () => void;
  setAudioBlob: (blob: Blob) => void;
  showInputElement: boolean;
}

interface RecordingState {
  isRecording: boolean;
  showStopBtn: boolean;
  userPressedStop: boolean;
  speechEndDetected: boolean;
  isProcessing: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  url,
  azureToken,
  onComplete,
  setAzureToken,
  setError,
  setAudioBlob,
  showInputElement,
}) => {
  // Audio and recognition states
  const [blob, setBlob] = useState<Blob>();
  const [recognizer, setRecognizer] = useState<sdk.SpeechRecognizer>();
  const [transcript, setTranscript] = useState<string[]>([]);
  const transcriptRef = useRef<string[]>(transcript);
  const recorder = useAudioRecorder();

  // UI states
  const [recordingState, setRecordingState] = useState<RecordingState>({
    isRecording: false,
    showStopBtn: false,
    userPressedStop: false,
    speechEndDetected: false,
    isProcessing: false,
  });
  const [isTyping, setIsTyping] = useState(false);
  const [azRecordingFailed, setAzRecordingFailed] = useState(false);
  const [isRecognizerReady, setIsRecognizerReady] = useState(false);

  const navigate = useNavigate();

  // Azure Speech recognizer setup
  const setupAzureRecognizer = (tokenObj: AzureToken): sdk.SpeechRecognizer => {
    if (!tokenObj.authToken || !tokenObj.region || tokenObj.error) {
      throw new Error("Invalid token configuration");
    }

    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage = "en-US";
    return new sdk.SpeechRecognizer(
      speechConfig,
      sdk.AudioConfig.fromDefaultMicrophoneInput()
    );
  };

  // Initialize recognizer
  useEffect(() => {
    const initializeRecognizer = async () => {
      try {
        const tokenObj = await getTokenOrRefresh();
        if (tokenObj.error) {
          console.error('Token refresh failed:', tokenObj.error);
          throw new Error("Failed to refresh token");
        }

        setAzureToken(tokenObj);
        const currRecognizer = setupAzureRecognizer(tokenObj);

        // Set up recognizer event handlers
        currRecognizer.recognized = (_, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            const newTranscript = [...transcriptRef.current, e.result.text];
            setTranscript(newTranscript);
            transcriptRef.current = newTranscript;
          } else {
            console.warn('Speech not recognized:', e.result.reason);
          }
        };

        currRecognizer.speechStartDetected = () =>
          setRecordingState(prev => ({ ...prev, speechEndDetected: false }));
        currRecognizer.speechEndDetected = () =>
          setRecordingState(prev => ({ ...prev, speechEndDetected: true }));
        currRecognizer.canceled = handleRecognizerCancellation;

        setRecognizer(currRecognizer);
        setIsRecognizerReady(true);
      } catch (error) {
        console.error("Error initializing recognizer:", {
          error,
          stack: error instanceof Error ? error.stack : undefined
        });
        handleError();
      }
    };

    if (!recognizer && !azRecordingFailed) {
      initializeRecognizer();
    }
  }, [recognizer, setAzureToken, azRecordingFailed]);

  // Handle recognizer cancellation
  const handleRecognizerCancellation = (s: sdk.Recognizer, e: sdk.CancellationEventArgs) => {
    console.error('Recognizer cancelled:', {
      errorCode: e.errorCode,
      errorDetails: e.errorDetails
    });

    if (e.errorCode === sdk.CancellationErrorCode.AuthenticationFailure) {
      console.warn('Authentication failure, attempting token refresh');
      refreshRecognizerToken();
    } else {
      handleError();
    }
  };

  // Error handling
  const handleError = () => {
    console.error('Recording failed, setting error state');
    setError(true);
    setAzRecordingFailed(true);
  };

  // Token refresh
  const refreshRecognizerToken = async () => {
    try {
      const tokenObj = await getTokenOrRefresh();
      if (tokenObj.error) {
        console.error('Token refresh failed:', tokenObj.error);
        throw new Error("Failed to refresh token");
      }

      setAzureToken(tokenObj);
      setRecognizer(setupAzureRecognizer(tokenObj));
      setError(false);
    } catch (error) {
      console.error("Error refreshing token:", {
        error,
        stack: error instanceof Error ? error.stack : undefined
      });
      handleError();
    }
  };

  // Handle recording blob updates
  useEffect(() => {
    if (!recorder.isRecording && recorder.recordingBlob) {
      setBlob(recorder.recordingBlob);
    }
  }, [recorder.recordingBlob, recorder.isRecording]);

  // Add this debug function
  const logStateOnStop = () => {
    console.log('Recording Stop State:', {
      isProcessing: recordingState.isProcessing,
      hasBlob: !!blob,
      transcriptLength: transcriptRef.current.length,
      userPressedStop: recordingState.userPressedStop,
      speechEndDetected: recordingState.speechEndDetected,
      isRecording: recorder.isRecording,
      recognizerState: recognizer?.recognizing
    });
  };

  // Modify the stopRecording function
  const stopRecording = () => {
    try {
      console.log('Stop recording initiated');
      recorder.stopRecording();
      recognizer?.stopContinuousRecognitionAsync();
      setRecordingState(prev => ({
        ...prev,
        showStopBtn: false,
        userPressedStop: true
      }));
      logStateOnStop();
    } catch (error) {
      console.error("Error stopping recording:", {
        error,
        stack: error instanceof Error ? error.stack : undefined
      });
      handleError();
    }
  };

  // Modify the useEffect for sending data
  useEffect(() => {
    const sendData = async () => {
      const { isProcessing, userPressedStop, speechEndDetected } = recordingState;

      // Add debug logging
      if (userPressedStop) {
        console.log('Send Data Check:', {
          isProcessing,
          hasBlob: !!blob,
          transcriptLength: transcriptRef.current.length,
          speechEndDetected,
          allConditionsMet: !isProcessing && blob &&
            transcriptRef.current.length > 0 &&
            userPressedStop &&
            speechEndDetected
        });
      }

      if (!isProcessing && blob && transcriptRef.current.length > 0 && userPressedStop && speechEndDetected) {
        setRecordingState(prev => ({ ...prev, isProcessing: true }));
        setIsTyping(true);

        try {
          const botResponse = await sendBlobToBackend(url, blob, transcript.join(". "));
          if (botResponse.status === 401) {
            console.error('Unauthorized access, redirecting to error page');
            navigate(`/error/${botResponse.status}`);
            return;
          }

          // Reset states and complete
          setIsTyping(false);
          setRecordingState(prev => ({
            ...prev,
            speechEndDetected: false,
            userPressedStop: false,
            isProcessing: false
          }));
          setTranscript([]);
          transcriptRef.current = [];

          onComplete(transcript, botResponse);
          if (recorder.recordingBlob) {
            setAudioBlob(recorder.recordingBlob);
          }
        } catch (error) {
          console.error("Error sending data to backend:", {
            error,
            stack: error instanceof Error ? error.stack : undefined,
            url,
            transcriptLength: transcript.length
          });
          handleError();
        }
      }
    };

    sendData();
  }, [blob, transcript, recordingState, url, onComplete]);

  // Recording controls
  const startRecording = () => {
    if (!isRecognizerReady) {
      console.warn('Attempted to start recording before recognizer was ready');
      return;
    }

    setTranscript([]);
    setRecordingState(prev => ({
      ...prev,
      showStopBtn: true,
      userPressedStop: false
    }));
    recorder.startRecording();
    recognizer?.startContinuousRecognitionAsync();
  };

  // Keyboard controls
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (document.activeElement?.id === "summary-box") return;
      if (e.code === 'Space' && !recorder.isRecording) {
        e.preventDefault();
        startRecording();
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (document.activeElement?.id === "summary-box") return;
      if (e.code === 'Space' && recorder.isRecording) {
        e.preventDefault();
        stopRecording();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [recorder.isRecording, isRecognizerReady]);

  return (
    <div>
      {azRecordingFailed && (
        <div className="z-50">
          <div id="error-message" className="text-red-500 text-sm font">
            Refresh this page to continue from where you left off
            <button
              className="text-red-500 hover:text-red-700 focus:outline-none"
              onClick={() => setAzRecordingFailed(false)}
            >
              <svg width="20" height="20" viewBox="0 0 40 40">
                <path d="M 10,10 L 20,20 M 20,10 L 10,20" stroke="black" strokeWidth="2" />
              </svg>
            </button>
          </div>
        </div>
      )}

      {showInputElement && (
        <div className="flex space-x-4 justify-center">
          {isTyping && <TypingBubble />}
          <div>
            {!isTyping && !recordingState.showStopBtn && (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={startRecording}
              >
                Start recording
              </button>
            )}
            {!isTyping && recordingState.showStopBtn && (
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={stopRecording}
              >
                Stop recording
              </button>
            )}
          </div>
        </div>
      )}

      {recorder.isRecording && recorder.mediaRecorder && (
        <div className="flex space-x-4 justify-center">
          <LiveAudioVisualizer
            mediaRecorder={recorder.mediaRecorder}
            width={200}
            height={75}
            barWidth={0.5}
            barColor="#051226"
          />
        </div>
      )}
    </div>
  );
};

export default ChatInput;
